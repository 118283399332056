import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { INavigationRoutes } from '../../constants/navigationRoutes';

const drawerWidth=240;

interface IDrawerNavigation {
    headerTitle: string;
    navItems: INavigationRoutes[];
    mobileOpen: boolean;
    setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;

}


export const MobileDrawerNavigation = ({navItems, mobileOpen, setMobileOpen, headerTitle}:IDrawerNavigation) => {
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    return (
        <Box component="nav">
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ my: 2 }}>
                        {headerTitle}
                    </Typography>
                    <Divider />
                    <List>
                        {navItems.map((item) => (
                            <ListItem key={item.displayValue} disablePadding>
                                <ListItemButton href={item.navigateTo} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={item.displayValue} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}
