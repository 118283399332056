import React from 'react'

interface IRenderConditionally{
    condition: Boolean,
    children?: React.ReactNode | any
}

export const RenderConditionally = (props: IRenderConditionally) => {
    if(!props.condition){
        return <></>
    }
    return(
        <>{props.children}</>
    )
}
