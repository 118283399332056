interface IPersonalDetails {
    FullName: string
    JobTitle: string
    LinkedinProfile: string
    ProgrammingLanguages: string[]
    Frameworks: string[]
    AwsServices: string[]
}

const PersonalDetails: IPersonalDetails = {
    FullName: 'Nathan Doherty',
    JobTitle: 'Senior Software Engineer',
    LinkedinProfile: 'https://www.linkedin.com/in/nathandoherty48',
    ProgrammingLanguages: ['C#', 'Java', 'JavaScript'],
    Frameworks: ['Spring Boot', 'React'],
    AwsServices: ['CodeBuild', 'ECS', 'Code Pipeline', 'Lambda']
}

export default PersonalDetails