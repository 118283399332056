import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './containers/home/Home';

function App() {
  return (
    <Routes>
      <Route path='/' Component={Home}/>
    </Routes>
  );
}

export default App;
