import { Box, Container, IconButton, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { Image } from '../../components/images/Image';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PersonalDetails from '../../constants/personalDetails';
import portrait from '../../assets/portrait.jpg'
import { RenderConditionally } from '../../components/utils/RenderConditionally';
import { isEmpty } from 'lodash'
import { CertifiedDeveloperAssociate, certifiedDeveloperUrl } from '../../components/certifications/aws/CertifiedDeveloperAssociate';

interface IHomeProps { }

const Home = (props: IHomeProps) => {

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'center'}>
      <Container>
        <Image
          image={portrait}
          containerSx={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '5%' }}
          sx={{ width: { xs: '75%', sm: '50%' } }}
          alternativeText='Portrait Image'
        />
        <Typography variant='h3' align='center'>{PersonalDetails.FullName}</Typography>
        <Typography variant='h4' align='center'>{PersonalDetails.JobTitle}</Typography>
        <br />
        <Typography variant='body1' align='center'>{PersonalDetails.JobTitle} with experience working with {PersonalDetails.ProgrammingLanguages.join(', ')}</Typography>
        <Typography variant='body1' align='center'>Utilizing frameworks such as {PersonalDetails.Frameworks.join(', ')}</Typography>
        <br />
        <Typography variant='body1' align='center'>Experienced AWS Engineer using services.<br /> Such as {PersonalDetails.AwsServices.join(', ')}</Typography>
        <Box sx={{ margin: 'auto', display: "flex", justifyContent: "center", alignItems: "center" }}>
          <RenderConditionally children={<LinkedInButton />} condition={isEmpty(PersonalDetails.LinkedinProfile) === false} />
        </Box>
        <CertifiedDeveloperAssociate/>
      </Container>
      <Container sx={{ paddingBottom: { xs: "2%", sm: 0 } }}>
        <Typography variant='body1' align='justify' sx={{ paddingTop: '5%' }}>
          I started my career working on my placement year as a Java Backend Engineer, working on an enterprise market data injection platform for a major Financial Institution.
          After this I went back and completed my final year of my BSc Computer Science, whereby I worked towards achieving my First Class Honors. This included working on my final
          year project where I Utilized AWS to deliver a web based Point Of Sale system that was able to work offline.
        </Typography>
        <Typography variant='body1' align='justify' sx={{ paddingTop: '2%' }}>
          Post graduation I joined HighRoads as a 'FullStack Engineer' on a predominantly Dotnet team, working with applications in React, dotnet framework,
          dotnet core and java. Throughout my time, I have been involved in enhancing the platform, introducing new functionality to both the backend and the UI.
          Alongside this I have been working within AWS, sharpening my skils and knowledge base, including working with the EC2 Image Builder in order to migrate
          our windows servers from managed 'Pets' to a fault taulerant and more manageable solution that has the applications pre installed, meaning that the number of
          instanes can be scaled with ease to meet the demand. This was previously manually configured hosts that where updated using Jenkins and Salt.
        </Typography>
        <Typography variant='body1' align='justify' sx={{ paddingTop: '2%' }}>
          I am always looking at new and different AWS services, and how I can use them to architect new functionality for the Platform, such as utilizing AWS lambda where
          appropriate to improve customer experience by reducing manual steps in the system and provide a seamless solution. As I have grown into my role, I have been more involved
          with the designing and architecting of solutions.
        </Typography>
        <Typography variant='body1' align='justify' sx={{ paddingTop: '2%' }}>
          In April 2023 I took up my new role as a 'Senior Software Engineer', working with the team towards the re-architecture and start of the migration of our legacy dotnet framework monoliths,
          into containerized dotnet 7 microservices that will reduce complexity and both improve readability, but also testability.
        </Typography>
        <Typography variant='body1' align='justify' sx={{ paddingTop: '2%' }}>
          In May 2023, I obtained my '<Link href={certifiedDeveloperUrl}>AWS Certified Developer - Associate</Link>' certification, showcasing my proficiency in working with AWS Services. Preparing for this exam
          has given me a broad range of experience with AWS Services, especially those I have not actively worked with as of yet. This in turn will improve how I design and build solutions to run in AWS.
        </Typography>
      </Container>
    </Stack>
  )
}

const LinkedInButton = () => {
  return (
    <>
      <IconButton href={PersonalDetails.LinkedinProfile} color='primary' sx={{ margin: 'auto' }}>
        <LinkedInIcon fontSize='large' />
      </IconButton>
    </>
  )
}

export default Home
