import { Stack, Button } from '@mui/material'
import React from 'react'
import { INavigationRoutes } from '../../constants/navigationRoutes';
import logo from '../../assets/logo.png'
import { useNavigate } from 'react-router-dom';
import { Image } from '../../components/images/Image';

interface IWebNavigation {
    headerTitle: string;
    navItems: INavigationRoutes[];
}

export const WebNavigation = ({ headerTitle, navItems }: IWebNavigation) => {
    const nav = useNavigate()
    const logoPosition = navItems.length > 0 ? 'flex-start' : 'center'
    return (
        <>
            <Image
                containerSx={{display:'flex', height:'100%', padding: '10px', justifyContent:{xs:'center', sm: logoPosition }}}
                sx={{ width: { xs: '70%', sm: '33%' } }}
                image={logo}
                alternativeText={headerTitle}
                onClick={()=>nav('/')}
            />
            <Stack spacing={10} direction={"row"} sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent:'flex-end'}} justifyContent={'flex-end'}>
                {navItems.map((item) => (
                    <Button href={item.navigateTo} key={item.displayValue} size={'large'} color='secondary'>
                        {item.displayValue}
                    </Button>
                ))}
            </Stack>
        </>
    )
}
