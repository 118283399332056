import React from 'react'
import { Image } from '../../images/Image'
import cert from '../../../assets/aws-certified-developer-associate.png'

export const CertifiedDeveloperAssociate = () => {
    return (
        <>
            <Image
                containerSx={{ width:{xs: '35%', md: '25%'} }}
                alternativeText={"AWS Certified Developer - Associate"}
                image={cert}
            />
        </>
    )
}

export const certifiedDeveloperUrl = "https://www.credly.com/badges/23e2f361-5ffa-4075-bf27-5cb5e33592f0/public_url"
