import { Theme } from '@emotion/react'
import { Box, Container, SxProps } from '@mui/material'
import React from 'react'

interface IImageProps {
    containerSx: SxProps<Theme>
    sx?: SxProps<Theme>
    alternativeText: string
    image?: string | undefined
    onClick?: Function
}

export const Image = (props: IImageProps) => {
    return (
        <>
            <Container sx={props.containerSx}>
                <Box
                    component={'img'}
                    src={props.image}
                    sx={props.sx ?? {width:'100%'}}
                    alt={props.alternativeText}
                    onClick={props.onClick ? props?.onClick() : ()=>{}}
                />
            </Container>
        </>
    )
}
