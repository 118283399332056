
export interface INavigationRoutes{
    displayValue: string;
    navigateTo: string;
}

export const emptyNavigationRoutes:INavigationRoutes[]=[]

export const navigationRoutes:INavigationRoutes[]=[
    {
        displayValue: "Home",
        navigateTo: "/"
    },
    {
        displayValue: "About",
        navigateTo: "/about"
    },
    {
        displayValue: "Services",
        navigateTo: "/services"
    },
    {
        displayValue: "Blog",
        navigateTo: "/blog"
    }
]