import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { MobileDrawerNavigation } from './MobileDrawerNavigation';
import { WebNavigation } from './WebNavigation';
import { emptyNavigationRoutes as navigationRoutes } from '../../constants/navigationRoutes';
import { RenderConditionally } from '../../components/utils/RenderConditionally';

interface Props {
    children?: React.ReactNode
}

const headerTitle = "Nathan Doherty Applications"

export default function Navigation(props: Props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar component="nav" enableColorOnDark position='sticky'>
                    <Toolbar>
                        <RenderConditionally condition={navigationRoutes.length > 0}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </RenderConditionally>
                        <WebNavigation headerTitle={headerTitle} navItems={navigationRoutes} />
                    </Toolbar>
                </AppBar>
                <MobileDrawerNavigation
                    headerTitle={headerTitle}
                    navItems={navigationRoutes}
                    mobileOpen={mobileOpen}
                    setMobileOpen={setMobileOpen}
                />
            </Box>
            {props.children}
        </>
    );
}